import React from 'react'
import {MDBTableHead } from 'mdbreact';

const HeaderTabla = ({array}) => {

	return(
        <MDBTableHead>
            <React.Fragment>
                <tr>
                    {array.map((item, index)=> {
                        return(
                            <th key={index}>{item}</th>
                        )
                    })}
                </tr>
            </React.Fragment>
        </MDBTableHead>
	)
}

export default HeaderTabla