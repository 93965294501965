import React, { useState } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { traeIdNombre, existeRegistro, recibeImagen, errores } from '../hooks/Funciones'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import moment from 'moment'


const SubirEditArchivos = ({ baseUrl, data, setData, TituloBtn, setFuncion }) => {

	const [files, setFile] = useState([])
	const [temporal, setTemporal] = useState([])

	const handleChangue = (e) => {
		setFile(e.target.files)
		setTemporal( Array.from(e.target.files));

	}

	
	const handleUpload = async () => {
		if(files.length > 0){
				let formData = new FormData()
		
				for(let i = 0; i < files.length; i++) {
					formData.append(`images[${i}]`, files[i])
					//console.log('files',files[i])
				}
				formData.append(`METHOD`, 'POST')
		
				//imprime formdata //for (const pair of formData.entries()) {console.log(pair[0] + ': ' + pair[1]);}
		
				const headers = {headers: {"Content-Type": "multipart/form-data",},};
				await axios.post(baseUrl, formData, headers)
					.then(response => {
						let respuesta = response.data
						if(typeof respuesta === 'object'){
							console.log('result', respuesta)
							console.log('result', [respuesta, ...data])
							setData([respuesta, ...data])
						}else{

							const objetos = response.data.split('}').map(item => {
								if (item !== '' && item !== undefined) {
									return JSON.parse(item + '}');
								}
							});
							objetos.pop();
							console.log('result', [...objetos, ...data])
							setData([...objetos, ...data])
							
						}
						setFuncion('ver')
					}).catch(error => {
						console.log(error);
					})
		}else{
			console.log('ingresa una foto')
		}
	}



	return (
		<div>
			<div className="row">
				<div className="col-md-12">
					<div><hr />

						<div className='row'>
							<div className='col-md-9'>

								<div className="input-group">
									<div className="input-group-prepend">
									</div>
									<div className="custom-file">
										<input type="file" multiple className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01"
											name="imagen"
											onChange={handleChangue}
										/>
										<label className="custom-file-label">
											Selecciona Imagenes (400px x 400px)
										</label>
									</div>
								</div>

							</div>
							<div className="col-md-12">
								{temporal.length > 0 ?
									temporal.map((file, index) => (
										<img
											key={index}
											src={URL.createObjectURL(file)}
											alt={`Preview ${file.name}`}
											style={{ width: "150px", height: "150px", objectFit: "cover", margin: 10 }}
										/>
									))
								:null}
							</div>
						</div>

						<br />
						<div className="col text-center">
							<button onClick={handleUpload} className="btn boton_estandar">{TituloBtn} </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubirEditArchivos