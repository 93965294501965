import React, {useState} from 'react'
import { FiMenu } from 'react-icons/fi';

const HeaderUserPrin = ({Logo, Avatar, nombre, Roll, idUsuario, usuario, menuActive, setMenuActive}) => {

    return(
        <div>
            <div className="contenedor linea">
                <img src={Logo} className="logo" />
            </div>

            <div className="HeaderUsuario">
                <div className="avatar">
                    <img src={Avatar} />
                </div>
                <div className="cont_datos_usuario">
                    <h5>{nombre}</h5>
                    <p>{Roll}</p>
                    <p><b>{usuario}</b></p>
                    <p>id: {idUsuario}</p>
                </div>
                <div className="btnMenu">
                    <button onClick={e => setMenuActive(!menuActive)}>
                        <FiMenu/>
                    </button>
                </div>
            </div>
            
        </div>
    )
}

export default HeaderUserPrin