import React, {useState, useEffect} from 'react'
import SubirEditArchivos from './SubirEditArchivos'
import Eliminar  from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import {Titulos} from '../hooks/Funciones'
import VerArchivos from './VerArchivos';
import axios from 'axios';
import Paginacion from '../componentes/Paginacion.js'

const ArchivosAdmin = ({urlArchivos, titulo}) => {

	const [tituloPag, setTituloPag] = useState(titulo)
	const [registroSelect, setRegistroSelect] = useState({})
	const [funcion, setFuncion] = useState('ver')
	const [archivos, setArchivos] = useState()
	const TitulosPagina = ['Ver Imagenes', 'Subir Imagenes', 'Editar Imagenen', 'Eliminar Imagene', 'Imagenes']
	const [copiaArray, setCopiaArray] = useState()
	const [busqueda, setBusqueda] = useState('')

	const PAGINACION = 15
	const [numPaginas, setNumPaginas] = useState()
	const [numRegistros, setNumRegistros] = useState()
	const [paginaAct, setPaginaAct] = useState(1);
	const [dataFiltrado, setDataFiltrado] = useState([])

	const peticionTraeDatos = async () => {
		await axios.get(urlArchivos)
			.then(response => {
				setArchivos(response.data)
				setCopiaArray(response.data)
				setDataFiltrado([...response.data].splice(0, PAGINACION))
				setNumPaginas(Math.ceil(response.data.length / PAGINACION))
				setNumRegistros(response.data.length)
				setFuncion('ver')
			}).catch(error => {
				console.log(error)
			})
	}


	const seleccionaRegistro = (dato, funcion) => e =>{
		setRegistroSelect({...dato, imagePrev: dato.imagen, nombre: dato.id})
		setFuncion(funcion === 'eliminar' ? 'eliminar' : 'editar')
	}

	const peticionDeletee = () =>{peticionDelete(registroSelect, urlArchivos, archivos, setArchivos, setFuncion)}
	useEffect(() => {Titulos (funcion, setTituloPag, TitulosPagina)},[funcion])

	const peticionDelete = async(registroSelect, baseUrl, data, setData, setFuncion) => {
		let f = new FormData();
		f.append("METHOD", "DELETE");
		await axios.post(baseUrl, f, {params: {id: registroSelect.id, path: registroSelect.path}})
		.then(response=>{setData(data.filter(opera=>opera.id!==registroSelect.id))
			console.log('respuesta detele', response)
			setFuncion('ver')
		}).catch(error=>{ console.log(error)})
	}

	const RecibeBusqueda = e => {
		setBusqueda(e.target.value)
		filtrar(e.target.value)
	}

	const filtrar = (termino) => {
		let resultado = copiaArray.filter((item) => {
			if(item.path.toLowerCase().includes(termino.toLowerCase())){return item} 
		})
		setDataFiltrado(resultado)
	}

	useEffect(()=>{
		peticionTraeDatos()
	},[])
	


	return(
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Pagina={'Cursos'} RecibeBusqueda={RecibeBusqueda} busqueda={busqueda}/>
            <Paginacion
						data={archivos}
						setDataFiltrado={setDataFiltrado}
						PAGINACION={PAGINACION}
						numPaginas={numPaginas}
						paginaAct={paginaAct}
						setPaginaAct={setPaginaAct}
					/>
			{funcion === 'crear' ?
                <SubirEditArchivos
					baseUrl={urlArchivos} 
					setFuncion={setFuncion}
					data={dataFiltrado} 
					setData={setDataFiltrado}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag} 
					funcion={funcion}/>
			:null}
			{funcion === 'ver' ?
				<VerArchivos
					data={dataFiltrado} 
					seleccionaRegistro={seleccionaRegistro}
					setFuncion={setFuncion}
					setRegistroSelect={setRegistroSelect}
					baseUrl={urlArchivos}
				/>
			:null}
			{funcion === 'eliminar' ?
				<Eliminar 
					nombre={'Imagen'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee}/>
				
			:null}
		</div>
	)
}

export default ArchivosAdmin