import {useEffect, useState} from 'react'
import axios from 'axios'

const PeticionTraeDatos = (Url) => {

    const [data, setData] = useState([])

    const peticionTraeDatosAll = async()=>{
        await axios.get(Url)
        .then (response => {setData(response.data)})
	} 

	const peticionTraeDatos = () =>{
		peticionTraeDatosAll()
	}

    useEffect(() =>{
        peticionTraeDatos()
    },[Url])
    return [data, setData]
}

export default PeticionTraeDatos