import React, { useState, useEffect } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import { errores } from '../hooks/Funciones'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTurnUp} from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used

const getRandomInt = (max) => {
	return Math.floor(Math.random() * max);
}

const SubirUsuario = ({ baseUrl, data, setData, setFuncion, Cursos, funcion, registroSelect }) => {

	const [error, setError] = useState({ activo: '' })
	const [claveNueva, setClaveNueva] = useState('')

	console.log('registroSelect',registroSelect)
	console.log('data',data)


	const [registro, setRegistro] = useState(
		funcion === 'editar' ?
			{ ...registroSelect, tel: registroSelect.telefono, cursos: JSON.parse(registroSelect.cursos) } :
			{ cursos: [], fecha_cad: moment(new Date()).format("YYYY-MM-DD"), activo: 'Aprobado' })


	const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

	const [cursosActuales, setCursosActuales] = useState([])

	const subeFecha = () => {
		setRegistro({ ...registro, fecha_cad: moment(registro.fecha_cad).add(2, 'months').format("YYYY-MM-DD")})
	}


	const estaonoArray = (dato) => {
		let array = registro.cursos
		let result = false
		array.map(item => { if (item === dato) { result = true } })
		return result
	}

	const subeCursos = (id) => e => {
		let array = registro.cursos
		console.log('array',array)
		if (estaonoArray(id)) {
			let index = array.indexOf(id);
			if (index > -1) { array.splice(index, 1); }
		} else { array = [...array, id] }

		setRegistro({ ...registro, cursos: array })
	}


	const cambiarCheckCurso = (id_curso, check, item) => {
		let cursosActualesArray = []
		cursosActuales.map((curso) => {
			if (curso.id_curso === id_curso) {
				return cursosActualesArray.push({ ...curso, checked: check, virtual: true })
			}

			return cursosActualesArray.push(curso)
		})
		setCursosActuales(cursosActualesArray)
	}

	//Comprueba si existe un usuario antes de guardarlo
	const existeUsuario = user => {
		let resultado = false
		data.map((item) => {
			let { usuario } = item
			if (usuario === user)
				resultado = true
		})
		return resultado
	}

	//Consulta para subir o crear los datos 
	const peticionPost = async () => {

		let fechaCad = moment(registro.fecha_cad).format("YYYY-MM-DD HH:mm:ss")
		const fechaHoy = moment(new Date()).format("YYYY-MM-DD");
		let { activo, usuario, clave, roll, nombre, email, pais, ciudad, estado, telefono, cursos } = registro

		if (!existeUsuario(usuario)) {
			if (activo !== undefined && usuario !== undefined && clave !== undefined && nombre !== undefined && roll !== undefined) {
				let idPedidoVirtual = ' ';

				let f = new FormData()
				f.append('activo', activo)
				f.append('usuario', usuario)
				f.append('clave', clave)
				f.append('roll', roll)
				f.append('nombre', nombre)

				f.append('fecha_ins', fechaHoy)
				f.append('fecha_cad', fechaCad)
				f.append('pais', pais)
				f.append('ciudad', ciudad)
				f.append('estado', estado)
				f.append('telefono', telefono)
				f.append('email', email)
				f.append('cursos', JSON.stringify(cursos))
				f.append('puntos', 0)
				f.append('id_stripe', ' ')
				f.append('temp', idPedidoVirtual)
				f.append('METHOD', 'POST')
				await axios.post(baseUrl, f)

					.then(response => {
						setData(data.concat(response.data))
						//setData(...data, response.data)
						console.log(response.data);
						setRegistro({})
						errores('Limpio', setError)
						setFuncion('ver')
					}).catch(error => {
						console.log(error);
					})
			} else errores('CampoVacio', setError)
		} else errores('Existe', setError)

	}

	const peticionPut = async () => {
		let fechaCad = moment(registro.fecha_cad).format("YYYY-MM-DD HH:mm:ss")
		const fechaHoy = moment(new Date()).format("YYYY-MM-DD");
		let { id, activo, usuario, clave, roll, nombre, email, pais, ciudad, estado, telefono, puntos, cursos } = registro

		console.log('a subur',cursos)
		console.log('a subur',registro)

		if (usuario !== undefined && clave !== undefined && nombre !== undefined && roll !== undefined && email !== undefined && pais !== undefined && ciudad !== undefined && estado !== undefined && telefono !== undefined) {
			let f = new FormData()
			f.append('activo', activo)
			f.append('usuario', usuario)
			f.append('clave', clave)
			f.append('claveNueva', claveNueva)
			f.append('roll', roll)
			f.append('nombre', nombre)

			f.append('fecha_ins', fechaHoy)
			f.append('fecha_cad', fechaCad)
			f.append('pais', pais)
			f.append('ciudad', ciudad)
			f.append('estado', estado)
			f.append('telefono', telefono)
			f.append('email', email)
			f.append('cursos', JSON.stringify(cursos))
			f.append('puntos', puntos)
			f.append('METHOD', 'PUT')
			await axios.post(baseUrl, f, { params: { id: registro.id } })

				.then(response => {
					console.log('res', response.data);
					let dataNueva = data
					dataNueva.map(item => {
						if (item.id === id) {
							item.activo = activo
							item.usuario = usuario
							item.clave = response.data.clave
							item.roll = roll
							item.nombre = nombre
							item.telefono = telefono

							item.fecha_ins = fechaHoy
							item.fecha_cad = fechaCad
							item.pais = pais
							item.ciudad = ciudad
							item.estado = estado
							item.email = email
							item.cursos = JSON.stringify(cursos)
						}
					})
					setData(dataNueva)
					setRegistro({ activo: false })
					errores('Limpio', setError)
					setClaveNueva('')
					setFuncion('ver')

				}).catch(error => {
					console.log(error);
				})
		} else errores('CampoVacio', setError)

	}
	return (
		<div>
			<div className="row">
				<div className="col-md-12">
					{error.activo ?
						<span className="error"> Error: {error.mensaje}</span> : null
					}
					<div><hr />

						<div className='row '>
							<div className='col-md-4'>
								<div className={`estados ${registro.activo}`} style={{ marginTop: 8 }}>
									{registro.activo}
								</div>
							</div>
							<div className='col-md-4'>
								<select className="browser-default custom-select selectEstado"
									name="estado"
									value={registro.activo}
									onChange={e => setRegistro({ ...registro, activo: e.target.value })}>
									<option value="Aprobado">Aprobado</option>
									<option value="Inactivo">Inactivo</option>
								</select>
							</div>
							<div className='col-md-4 sinMargen'>
								<MDBInput
									style={{ marginTop: 5, marginBottom: 0 }}
									label="Puntos" name="puntos" className="disabled sinMargen" outline
									value={registro.puntos}
									onChange={e => setRegistro({ ...registro, puntos: e.target.value })}
								/>
							</div>
							<div className='col-md-6'>
								<MDBInput
									label="Nombre Completo del Usuario" name="nombre" required className={error.campoNombreUser ? "form-control is-invalid" : null} outline
									value={registro.nombre}
									onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
								/>

							</div>
							<div className='col-md-6'>
								<MDBInput
									label="E-mail" name="email" required className={error.campoNombreUser ? "form-control is-invalid" : null} outline
									value={registro.email}
									onChange={e => setRegistro({ ...registro, email: e.target.value })}
								/>

							</div>
						</div>

						<div className='row sinMargen'>
							<div className='col-md-6'>
								<MDBInput name="usuario" required className={error.campoUsuario ? "form-control is-invalid" : null} outline
									label={'Usuario'}
									value={registro.usuario}
									onChange={e => setRegistro({ ...registro, usuario: e.target.value })}
								/>
							</div>
							<div className='col-md-6'>
								{funcion === 'editar' ?
									<MDBInput label="Clave nueva" type="password" name="clave" required className={error.campoClave ? "form-control is-invalid" : null} outline
										value={claveNueva}
										onChange={e => setClaveNueva(e.target.value)}
									/>
									:
									<MDBInput label={funcion === 'editar' ? "Clave nueva" : "Clave"} type="password" name="clave" required className={error.campoClave ? "form-control is-invalid" : null} outline
										value={registro.clave}
										onChange={e => setRegistro({ ...registro, clave: e.target.value })}
									/>
								}
							</div>
						</div>

						<div className='row sinMargen'>
							<div className='col-md-4'>
								<MDBInput name="Pais" required className={error.campoUsuario ? "form-control is-invalid" : null} outline
									label={'País'}
									value={registro.pais}
									onChange={e => setRegistro({ ...registro, pais: e.target.value })}
								/>

							</div>
							<div className='col-md-4'>
								<MDBInput name="Estado" required className={error.campoUsuario ? "form-control is-invalid" : null} outline
									label={'Estado / Departamento'}
									value={registro.estado}
									onChange={e => setRegistro({ ...registro, estado: e.target.value })}
								/>

							</div>
							<div className='col-md-4'>
								<MDBInput name="Ciudad" required className={error.campoUsuario ? "form-control is-invalid" : null} outline
									label={'Ciudad'}
									value={registro.ciudad}
									onChange={e => setRegistro({ ...registro, ciudad: e.target.value })}
								/>
							</div>
						</div>

						<div className='row sinMargen'>
							<div className='col-md-4'>
								<MDBInput name="Telefono" required className={error.campoUsuario ? "form-control is-invalid" : null} outline
									label={'Teléfono'}
									value={registro.telefono}
									onChange={e => setRegistro({ ...registro, telefono: e.target.value })}
								/>
							</div>
							<div className='col-md-4'>
								<div>
									{/* <br/> */}
									<select
										className={error.campoRoll ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="roll"
										value={registro.roll}
										onChange={e => setRegistro({ ...registro, roll: e.target.value })}>
										<option value="">Selecciona Roll</option>
										<option value="Admin">Admin</option>
										<option value="Estudiante">Estudiante</option>
									</select>
									<br />
								</div>
							</div>
							<div className='col-md-4'>
								<div style={{display: 'flex'}}>
									<div style={{flex: 3}}>
										<span style={{ fontSize: 13 }}><b>Caducidad: </b></span>
									</div>
									<div style={{flex: 1, paddingLeft: 2}}>
										<input className='form-control'
											type="date"
											value={registro.fecha_cad}
											onChange={(text) => setRegistro({ ...registro, fecha_cad: text.target.value })}
										/>
									</div>
									<div style={{flex: 3}}>
										<button
											style={{marginTop: '-5px'}}
											onClick={subeFecha}
											className="btn boton_estandar"
										>
											<FontAwesomeIcon icon={faTurnUp} size="1x"/>
										</button>
									</div>

								</div>
							</div>
						</div>

						<div className='col-md-12'>
								<div>
									<h5>Seleccione Cursos:</h5>
									{Cursos.map((item, index) => {
										if (item.activo === 'true') {
											return (
												<div className="custom-control custom-checkbox custom-control-inline" key={index}>
													<input type="checkbox" className="custom-control-input" name={item.id} id={item.nombre}
														onChange={subeCursos(item.id)} checked={estaonoArray(item.id)} />
													<label className="custom-control-label" htmlFor={item.nombre}>{item.nombre}</label>
												</div>
											)
										}
									})
									}
								</div>
							</div>


						<br />
						<div className="col text-center">
							<button
								onClick={funcion === 'editar' ? peticionPut : peticionPost}
								className="btn boton_estandar"
							>{`Subir Usuario`}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubirUsuario