import React, { useState, useEffect } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { traeIdNombre, TraeNombre, existeRegistro, recibeImagen, recibePdf, errores } from '../hooks/Funciones'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import AutoCompleta from '../componentes/AutoCompleta'
import moment from 'moment'
import CreaQuiz from './CreaQuiz';
import Iframe from 'react-iframe'
import AgregarImagenes from './AgregarImagenes'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, htmlToDraft, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';


const SubirEditCurso = ({ baseUrl, data, setData, setFuncion, registroSelect, TituloBtn, funcion, cursoSelect, tamOrden }) => {

	console.log('tam',tamOrden)

	const [registro, setRegistro] = useState(
		funcion === 'editar' ?
			{
				...registroSelect,
				padre: TraeNombre(data, registroSelect.padre),

				producto: registroSelect.nombre_producto !== null || registroSelect.nombre_producto !== undefined ?
					{
						nombre_producto: registroSelect.nombre_producto,
						precio_producto: registroSelect.precio_producto,
						precio_rebajado: registroSelect.precio_rebajado,
						id: registroSelect.id_producto,
						url_imagen: JSON.parse(registroSelect.url_imagen)
					}
					: { url_imagen: [] },
				imagePrev: registroSelect.imagePrev === '' ? fotoVacia : registroSelect.imagePrev
			} :
			{
				orden: tamOrden+1,
				activo: true,
				imagePrev: fotoVacia,
				producto: { url_imagen: [] },
				archivo: { name: '' },
				video_url: ''
	})
	const [error, setError] = useState({ activo: false })
	const [imagenOld, setImagenOld] = useState(registro.imagen)
	const [archivoOld, setArchivoOld] = useState(registro.archivo)
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
	const [quiz, setQuiz] = useState([])
	const [orden, setOrden] = useState([])


	useEffect(() => {
		if (funcion === 'editar') {
			console.log(registroSelect)
			const contentBlocks = convertFromHTML(registroSelect.contenido);

			const contentState = ContentState.createFromBlockArray(
				contentBlocks.contentBlocks,
				contentBlocks.entityMap
			);

			console.log(contentState)

			setEditorState(EditorState.createWithContent(contentState));
			if (registroSelect.tipo === 'quiz' || registroSelect.tipo === 'trabajo') {
				 setQuiz(JSON.parse(registroSelect.contenido)) 
				}

		}
	}, [registroSelect]);


	const creaFormData = (registro, METHOD, NombreIma, NombreArch) => {

		let { id, nombre, orden, tipo, padre, contenido, video_url, url_files, id_curso, producto, imagen, imagenNueva, nombreImaNueva, archivo, archivoNuevo, nombreArchivoNuevo } = registro
		//let {id, nombre, cedula, runt, telefono, ids_tipoingresos, imagen, imagenNueva, nombreImaNueva} = registro

		console.log('producto', producto.id)
		let f = new FormData()
		if (METHOD === 'PUT') { f.append('id', id) }
		f.append('nombre', nombre)
		f.append('tipo', tipo)
		f.append('orden', orden)
		f.append('padre', traeIdNombre(data, padre))
		f.append('contenido',  tipo === 'quiz' || tipo === 'trabajo' ? JSON.stringify(quiz) : draftToHtml(convertToRaw(editorState.getCurrentContent())))
		f.append('video_url', video_url)
		f.append('id_curso', cursoSelect)
		f.append('producto', producto.id !== undefined ? producto.id : 'null')
		f.append('METHOD', METHOD)

		if (METHOD === 'PUT') {
			f.append('imagen', imagenNueva !== undefined ? imagenNueva : imagen)
			f.append('nombre_imagen', nombreImaNueva)

			console.log('archivoNuevo',archivoNuevo)
			f.append('archivo', archivoNuevo !== undefined ? archivoNuevo : archivo)
			f.append('nombre_archivo', nombreArchivoNuevo)
			if (imagenNueva !== undefined) { f.append('nombre_imagen_vieja', imagenOld) }
			if (archivoNuevo !== undefined) { f.append('nombre_archivo_viejo', archivoOld) }
		} else if (METHOD === 'POST') {
			f.append('imagen', imagen)
			f.append('nombre_imagen', NombreIma)
			f.append('archivo', archivo)
			f.append('nombre_archivo', NombreArch)
		}
		return f

	}

	//Consulta para subir o crear los datos 
	const peticionPost = async () => {

		const fecha = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

		let { nombre, tipo, padre, video_url, url_files, producto, imagen, archivo, orden } = registro


		if (!existeRegistro(data, nombre)) {
			if (nombre !== undefined && tipo !== undefined, orden !== undefined) {

				if (imagen !== undefined) {

					let formato = imagen.type.replace('image/', '.');
					let NombreImagen = imagen.name.replace(/[^A-Z0-9]+/ig, "_");
					let RutaServ = 'images/'
					let formatosPermitidos = ['.png', '.jpeg', '.jpg']

					if (imagen.size < 2000000 && formatosPermitidos.includes(formato)) {


						let NombreIma = RutaServ + fecha + NombreImagen + formato

						// EXISTE UN ARCHIVO? -------------------------------

						if (archivo.name !== '') {

							//let formatAr = archivo.type.replace('image/', '.');
							let formatAr = '.pdf';
							let NombreArchivo = archivo.name.replace(/[^A-Z0-9]+/ig, "_");
							let RutaServArch = 'archivos/'
							let formatosPermitidosar = ['.pdf']

							if (archivo.size < 5000000 && formatosPermitidosar.includes(formatAr)) {

								let NombreArch = RutaServArch + fecha + NombreArchivo + formatAr
								let f = creaFormData(registro, 'POST', NombreIma, NombreArch)

								await axios.post(baseUrl, f)

									.then(response => {
										//console.log('result', response.data)
										let resultFormat = {
											...response.data,
											archivoPrev: NombreArch,
											archivo: NombreArch,
											imagePrev: NombreIma,
											imagen: NombreIma,
											contenido: tipo === 'quiz' || tipo === 'trabajo' ? JSON.stringify(quiz) : response.data.contenido,
											
											nombre_producto: registro.producto.nombre_producto,
											precio_producto: registro.producto.precio_producto,
											precio_rebajado: registro.producto.precio_rebajado,
											id_producto: registro.producto.id_producto,
											url_imagen: JSON.stringify(registro.producto.url_imagen)
										}
										console.log('result', resultFormat)
										setData([resultFormat, ...data])
										setRegistro({ activo: false })
										errores('Limpio', setError)
										setFuncion('ver')

									}).catch(error => {
										console.log(error);
									})
							} else errores('Pdf', setError)
							// SI NO EXISTE ARCHIVO Y SOLO EXISTE IMAGEN: -------------------------------
						} else {
							console.log('aca')
							let f = creaFormData(registro, 'POST', NombreIma)
							await axios.post(baseUrl, f)

								.then(response => {
									//console.log('result', response.data)
									let resultFormat = { 
										...response.data, 
										imagePrev: NombreIma, 
										imagen: NombreIma, 
										archivo: 'undefined',
										contenido:  tipo === 'quiz' || tipo === 'trabajo' ? JSON.stringify(quiz) : response.data.contenido ,
										
										nombre_producto: registro.producto.nombre_producto,
										precio_producto: registro.producto.precio_producto,
										precio_rebajado: registro.producto.precio_rebajado,
										id_producto: registro.producto.id_producto,
										url_imagen: JSON.stringify(registro.producto.url_imagen)
									}
									console.log('result', resultFormat)
									setData([resultFormat, ...data])
									setRegistro({ activo: false })
									errores('Limpio', setError)
									setFuncion('ver')

								}).catch(error => {
									console.log(error);
								})
						}

					} else errores('Imagen', setError)
				} else {

					// EXISTE UN ARCHIVO? -------------------------------

					if (archivo.name !== '') {

						//let formatAr = archivo.type.replace('image/', '.');
						let formatAr = '.pdf';
						let NombreArchivo = archivo.name.replace(/[^A-Z0-9]+/ig, "_");
						let RutaServArch = 'archivos/'
						let formatosPermitidosar = ['.pdf']

						if (archivo.size < 5000000 && formatosPermitidosar.includes(formatAr)) {

							let NombreArch = RutaServArch + fecha + NombreArchivo + formatAr
							let f = creaFormData(registro, 'POST', '', NombreArch)

							await axios.post(baseUrl, f)

								.then(response => {
									//console.log('result', response.data)
									let resultFormat = {
										...response.data, 
										archivoPrev: NombreArch, 
										archivo: NombreArch, 
										contenido:  tipo === 'quiz' || tipo === 'trabajo' ? JSON.stringify(quiz) : response.data.contenido,
										nombre_producto: registro.producto.nombre_producto,
											precio_producto: registro.producto.precio_producto,
											precio_rebajado: registro.producto.precio_rebajado,
											id_producto: registro.producto.id_producto,
											url_imagen: JSON.stringify(registro.producto.url_imagen)
									}
									console.log('result', resultFormat)
									setData([resultFormat, ...data])
									setRegistro({ activo: false })
									errores('Limpio', setError)
									setFuncion('ver')

								}).catch(error => {
									console.log(error);
								})
						} else errores('Pdf', setError)
						// SI NO EXISTE ARCHIVO ni EXISTE IMAGEN: -------------------------------
					} else {
						let f = creaFormData(registro, 'POST', '')


						await axios.post(baseUrl, f)

							.then(response => {
								//console.log('result', response.data)
								console.log('lego aca')
								let resultFormat = { 
									...response.data, imagePrev: '', 
									archivo: 'undefined',
									imagen: '', 
									contenido:  tipo === 'quiz' || tipo === 'trabajo' ? JSON.stringify(quiz) : response.data.contenido ,
									nombre_producto: registro.producto.nombre_producto,
									precio_producto: registro.producto.precio_producto,
									precio_rebajado: registro.producto.precio_rebajado,
									id_producto: registro.producto.id,
									url_imagen: JSON.stringify(registro.producto.url_imagen)
								}
								console.log('result', resultFormat)
								setData([resultFormat, ...data])
								setRegistro({ activo: false })
								errores('Limpio', setError)
								setFuncion('ver')

							}).catch(error => {
								console.log(error);
							})
					}

				}
			} else errores('CampoVacio', setError)
		} else errores('Existe', setError)

	}

	const ProcedimientoPut = async () => {

		let { id, orden, nombre, tipo, padre, video_url, url_files, contenido, producto, imagen, archivo, imagenNueva, nombreImaNueva, nombreArchivoNuevo, archivoNuevo } = registro
		//let { id, nombre, activo, imagen, descripcion, imagenNueva, nombreImaNueva } = registro

		let f = creaFormData(registro, 'PUT')
		if (imagenNueva !== undefined) { f.append('nombre_imagen_vieja', imagenOld) }
		if (archivoNuevo !== undefined) { f.append('nombre_archivo_viejo', archivoOld) }

		await axios.post(baseUrl, f)

			.then(response => {

				console.log(response.data)
				let dataNueva = data
				dataNueva.map(item => {
					if (item.id === id) {
						item.nombre = nombre
						item.tipo = tipo
						item.orden = orden
						item.padre = traeIdNombre(data, padre)
						item.video_url = video_url
						item.url_files = url_files
						item.producto = producto
						item.contenido = tipo === 'quiz' || tipo === 'trabajo' ? JSON.stringify(quiz) : draftToHtml(convertToRaw(editorState.getCurrentContent()))

						item.imagePrev = imagen
						item.imagen = imagen

						item.archivoPrev = archivo
						item.archivo = archivo
						//cuando hay una imagen nueva
						if (imagenNueva !== undefined) {
							item.imagePrev = nombreImaNueva === '' ? imagenNueva : nombreImaNueva
							item.imagen = nombreImaNueva === '' ? imagenNueva : nombreImaNueva
						}

						//cuando hay una archivo nuevo
						if (archivoNuevo !== undefined) {
							item.archivoPrev = nombreArchivoNuevo === '' ? archivoNuevo : nombreArchivoNuevo
							item.archivo = nombreArchivoNuevo === '' ? archivoNuevo : nombreArchivoNuevo
						}

					}
				})
				//setData(dataNueva)
				setData(dataNueva.sort((a, b) => a.orden - b.orden))
				console.log('enviado')
				errores('Limpio', setError)
			}).catch(error => {
				console.log('muestra erro: ' + error);
			})
		setFuncion('ver')
	}

	const peticionPut = () => {

		let { nombre, tipo, padre, video_url, url_files, producto, imagen, archivo, imagenNueva, archivoNuevo } = registro
		console.log('registro', registro)

		if (nombre !== undefined && tipo !== undefined) {
			if (imagenNueva !== undefined) {
				//Cuando Hay una imagen nueva
				let formatosPermitidos = ['.png', '.jpeg', '.jpg', '.JPEG']
				console.log('imagenNueva', imagenNueva)
				let formato = typeof (imagenNueva) !== "string" ? imagenNueva.type.replace('image/', '.') : null;
				//Pregunta si el formato y peso son correctos
				if (imagenNueva !== '' && imagenNueva.size < 2000000 && formatosPermitidos.includes(formato)) {
					//pregunta si existe archivo
					if (archivoNuevo !== undefined) {

						let formatAr = '.pdf'; let formatosPermitidosar = ['.pdf']
						if (archivoNuevo.size < 5000000 && formatosPermitidosar.includes(formatAr)) {
							ProcedimientoPut()
						} else errores('Pdf', setError)
						// SI NO EXISTE ARCHIVO Y SOLO EXISTE IMAGEN: -------------------------------
					} else {
						ProcedimientoPut()
					}

				} else { errores('ImagenIncorrecta', setError) }
			} else {
				// EN CASO DE QUE NO HAYA IMAGEN PERO SI ARCHIVO: -------------------------------
				if (archivoNuevo !== undefined) {

					let formatAr = '.pdf'; let formatosPermitidosar = ['.pdf']
					if (archivoNuevo.size < 5000000 && formatosPermitidosar.includes(formatAr)) {
						ProcedimientoPut()
					} else errores('Pdf', setError)
					// SI NO EXISTE ARCHIVO Y SOLO EXISTE IMAGEN: -------------------------------
				} else {
					console.log('aca')
					ProcedimientoPut()
				}
			}

		} else errores('CampoVacio', setError)

	}

	const updateTextDescription = async (state) => {
		await setEditorState(state);
		const data = convertToRaw(editorState.getCurrentContent())
	}

	const uploadImageCallBack = (file) => {
		return new Promise(
			(resolve, reject) => {
				const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
				xhr.open('POST', 'https://api.imgur.com/3/image');
				xhr.setRequestHeader('Authorization', 'Client-ID 8d26ccd12712fca');
				const data = new FormData(); // eslint-disable-line no-undef
				data.append('image', file);
				xhr.send(data);
				xhr.addEventListener('load', () => {
					const response = JSON.parse(xhr.responseText);
					resolve(response);
				});
				xhr.addEventListener('error', () => {
					const error = JSON.parse(xhr.responseText);
					reject(error);
				});
			},
		);
	}

	useEffect(() =>{
		console.log('tamOrden',tamOrden)
		let array = [...Array(tamOrden + 1)].map((_, i) => i + 1)
		setOrden(array)
	},[tamOrden])

	return (
		<div>
			<div className="row">
				<div className="col-md-12">
					{error.activo ? <span className="error"> Error: {error.mensaje}</span> : null}
					<div><hr />

						<div className='row'>
							{/* COLUMNA IZQUIERDA ------------------------------------ */}
							<div className='col-md-9'>
								<MDBInput
									label="Nombre de la Clase" name="nombre" required className={error.nombre ? "form-control is-invalid" : null} outline
									value={registro.nombre}
									onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
								/>

								<div className='row'>
									<div className='col-md-6'>
										<select
											className={error.campoRoll ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="roll"
											value={registro.tipo}
											onChange={e => setRegistro({ ...registro, tipo: e.target.value })}>
											<option value="">Selecciona Tipo de Clase</option>
											<option value="unidad">Unidad</option>
											<option value="video">Video</option>
											<option value="texto">Texto</option>
										</select>
									</div>
									<div className='col-md-6'>
										<select
											className={error.campoRoll ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="roll"
											value={registro.padre}
											onChange={e => setRegistro({ ...registro, padre: e.target.value })}>
											<option value="">Selecciona Unidad</option>
											{data.map(item => {
												if (item.tipo === 'unidad') {
													return (<option key={item.id} value={item.nombre}>{item.nombre}</option>)
												}
											})}
										</select>
									</div>
								</div>


								{registro.tipo === 'unidad' || registro.tipo === 'video' || registro.tipo === 'texto' || registro.tipo === undefined || registro.tipo === '' ?

									<div>
										{registro.tipo === 'video' ?
											<div>

												<MDBInput
													label="Url Vimeo" name="video_url" required className={error.nombre ? "form-control is-invalid" : null} outline
													value={registro.video_url}
													onChange={e => setRegistro({ ...registro, video_url: e.target.value })}
												/>

											</div>
											: null}
										<br></br>

										<div className='row'>
											<div className='col-md-8'>
												<Editor
												editorState={editorState}
												toolbarClassName="toolbarClassName"
												wrapperClassName="wrapperClassName"
												editorClassName="editorClassName"

												onEditorStateChange={updateTextDescription}
												toolbar={{
													image: {
														uploadEnabled: false, 
														previewImage: true,
														uploadCallback: uploadImageCallBack,
														alt: { present: true, mandatory: false },
													},
												}}
											/>
											</div>
											<div className='col-md-4'>
												<div style={{maxHeight: 315, overflowY: 'scroll', overflowX: 'hidden'}} dangerouslySetInnerHTML={{__html: draftToHtml(convertToRaw(editorState.getCurrentContent()))}} />
											</div>
										</div>
										
										

										{
											
										}

										{/* <div className="form-group">
											<AgregarImagenes registro={registro}/>
										</div> */}

										<div className="input-group">
											<div className="custom-file">
												<input type="file" className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01"
													name="archivo"
													onChange={recibePdf(registro, setRegistro, funcion)}
												/>
												<label className="custom-file-label">
													Seleccionar PDF
												</label>
											</div>
										</div>
										<p style={{ fontSize: 15, color: 'gray', marginLeft: 5 }}>
											<b>  Nombre Archivo:
												{registro.archivo.name !== undefined || registro.archivoNuevo !== undefined ?
													registro.archivoNuevo !== undefined ?
														` ${registro.archivoNuevo.name} - (${Math.round(registro.archivoNuevo.size / 1024)}) KB)`
														: ` ${registro.archivo.name} - (${Math.round(registro.archivo.size / 1024)}) KB)`
													: registro.archivo === 'undefined' ? '' : ` ${registro.archivo}`
												}
											</b>
										</p>
									</div>

									:null
								}

							</div>








							{/* COLUMNA DERECHA ------------------------------------ */}
								<div className="col-md-3">
									{registro.tipo === 'video' ?
										<Iframe
											// url="https://player.vimeo.com/video/734753832"
											url={registro.video_url}
											width="100%"
											height="180px"
											className="VideoIframe"
											display="block"
											position="relative" />
									: registro.tipo === 'texto' || registro.tipo === 'unidad' || registro.tipo === undefined ?
										<div>
											<div className='PublicidadProd'>
												<h4>Portada:</h4>
												<div className="FotoPrev">

													{funcion === 'editar' ?
														<img alt="imagen" src={
															registro.imagePrev === registro.imagen ?
																baseUrl + "/" + registro.imagePrev
																: registro.imagePrev
														} />
														:
														<img src={registro.imagePrev} />
													}
												</div>
											</div>

											<br></br>
											<div className="input-group">
												<div className="input-group-prepend">
												</div>
												<div className="custom-file">
													<input type="file" className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01"
														name="imagen"
														onChange={recibeImagen(registro, setRegistro, funcion)}
													/>
													<label className="custom-file-label">
														Seleccionar Foto
													</label>
												</div>
											</div>
										</div>
										:null
									}
									<br></br>
									<div className='row'>
										<div className='col-md-12'>
											<select
												className={error.campoRoll ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="roll"
												value={registro.orden}
												onChange={e => setRegistro({ ...registro, orden: e.target.value })}>
													<option value="">Orden</option>
													{orden.map((item) => {
														return(<option key={item} value={item}>{item}</option>)
													})}
											</select>
										</div>
									</div>
								</div>
						</div>

						<br />
						<div className="col text-center">
							<button onClick={funcion === 'editar' ? peticionPut : peticionPost} className="btn boton_estandar">{TituloBtn} </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubirEditCurso