import React, {useState, useEffect} from 'react'
import FormularioLogin from '../componentesAdmin/login/formulario_login.js'
import axios from 'axios'
import md5 from 'md5'
import Cookies from 'universal-cookie'
//import { useNavigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


const Login = ({_config}) => {

	//const {baseUrl, titulo, sede} = _config._config
	const baseUrl = process.env.REACT_APP_BASE_URL
	const titulo = "Juanita Rubio Admin App"
	const sede = "Admin App"
	
	//estados:
	const baseUrlUsers = baseUrl+'apijuanita/usuarios/';
	const cookies = new Cookies()
	const [form,setForm] = useState ({usuario: '', clave: ''})
	const [errorForm,setErrorForm] = useState ('')
	const navigate = useNavigate();


	//función click
	const onSubmit = async e => {
		e.preventDefault()

		await axios.get(baseUrlUsers, {params: {usuario: form.usuario, clave: md5(form.clave), unico:'true'}})
		.then (response => {
			let respuesta = response.data
			console.log(respuesta)
			if(respuesta){
				if(respuesta.activo === 'Aprobado' && respuesta.roll === 'Admin'){
					cookies.set('id', respuesta.id, {path: "/"})
					cookies.set('nombre', respuesta.nombre, {path: "/"})
					cookies.set('roll', respuesta.roll, {path: "/"})
					cookies.set('usuario', respuesta.usuario, {path: "/"})
					cookies.set('nombre', respuesta.nombre, {path: "/"})
					navigate('../Admin');
				}
			}else{setErrorForm('Usuario o Contraseña Incorrecta')}
		})
		.catch(error =>{
			console.log(error)
			setErrorForm('No encuentra el servidor')
		})
	}

	//función escucha form
	const onChange = e => {
		setForm({...form, [e.target.name]: e.target.value})
	}

	useEffect(() => {
		document.title = "Iniciar sesión - "+titulo
		if(cookies.get('usuario')){
			navigate("../admin")
			console.log('cookie', cookies.get('usuario'))
			//history.push("../admin");
			//alert('correcto')
		}else{
			console.log('no tiene cokkies')
		}
	},[])	

		return(
			<div>
				<FormularioLogin 
						onChange={onChange}
						form={form}
						onSubmit={onSubmit}
						errorForm={errorForm}
						sede={sede}/>
			</div>
		)
}



export default Login