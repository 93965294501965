import React, { useState } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { traeIdNombre, existeRegistro, recibeImagen, errores } from '../hooks/Funciones'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import moment from 'moment'


const SubirEditCurso = ({ baseUrl, data, setData, setFuncion, registroSelect, TituloBtn, funcion }) => {

	const [registro, setRegistro] = useState(funcion === 'editar' ? registroSelect : { activo: true, imagePrev: fotoVacia })
	const [error, setError] = useState({ activo: false })
	const [imagenOld, setImagenOld] = useState(registro.imagen)

	const creaFormData = (registro, METHOD, NombreIma) => {

			let {id, nombre, activo, imagen, imagenNueva, nombreImaNueva, descripcion } = registro
			//let {id, nombre, cedula, runt, telefono, ids_tipoingresos, imagen, imagenNueva, nombreImaNueva} = registro
			
			let f = new FormData()
			if (METHOD === 'PUT'){f.append('id', id)} 
			f.append('nombre', nombre)
			f.append('activo', activo)
			f.append('descripcion', descripcion)
			f.append('METHOD', METHOD)

			if (METHOD === 'PUT'){
				f.append('imagen', imagenNueva !== undefined ? imagenNueva : imagen)
				f.append('nombre_imagen', nombreImaNueva)
				if (imagenNueva !== undefined){f.append('nombre_imagen_vieja', imagenOld)}
			}else if (METHOD === 'POST'){
				f.append('imagen', imagen)
				f.append('nombre_imagen', NombreIma)
			} 
			return f
	}

	//Consulta para subir o crear los datos 
	const peticionPost = async () => {

		const fecha = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

		let { nombre, activo, imagen, descripcion } = registro

		let formato = imagen.type.replace('image/', '.');
		let NombreImagen = imagen.name.replace(/[^A-Z0-9]+/ig, "_");
		let RutaServ = 'images/'
		console.log('nombreImagenFormat: ' + fecha + NombreImagen + formato)

		let formatosPermitidos = ['.png', '.jpeg', '.jpg']


		if (!existeRegistro(data, nombre)) {
			if (nombre !== undefined && imagen !== undefined && descripcion !== undefined) {
				if (imagen.size < 2000000 && formatosPermitidos.includes(formato)) {
					
					let NombreIma = RutaServ+fecha+NombreImagen+formato
					let f = creaFormData(registro, 'POST', NombreIma)

					await axios.post(baseUrl, f)

						.then(response => {
							console.log('result', response.data)
							let resultFormat = { ...response.data, imagePrev: NombreIma, imagen: NombreIma }
							setData([resultFormat, ...data])
							setRegistro({ activo: false })
							errores('Limpio', setError)
							setFuncion('ver')

						}).catch(error => {
							console.log(error);
						})
				} else errores('Imagen', setError)
			} else errores('CampoVacio', setError)
		} else errores('Existe', setError)

	}

	const ProcedimientoPut = async () => {

		let {id, nombre, activo, imagen, descripcion, imagenNueva, nombreImaNueva } = registro

		let f = creaFormData(registro, 'PUT')
		if (imagenNueva !== undefined) { f.append('nombre_imagen_vieja', imagenOld) }

		await axios.post(baseUrl, f)

			.then(response => {

				console.log(response.data)
				let dataNueva = data
				dataNueva.map(item => {
					if (item.id === id) {
						item.nombre = nombre
						item.activo = activo
						item.descripcion = descripcion
						item.imagePrev = imagen
						item.imagen = imagen
						//cuando hay una imagen nueva
						if (imagenNueva !== undefined) {
							item.imagePrev = nombreImaNueva === '' ? imagenNueva : nombreImaNueva
							item.imagen = nombreImaNueva === '' ? imagenNueva : nombreImaNueva
						}
					}
				})
				setData(dataNueva)
				console.log('enviado')
				errores('Limpio', setError)
			}).catch(error => {
				console.log('muestra erro: ' + error);
			})
		setFuncion('ver')
	}

	const peticionPut = () => {

		let { nombre, activo, descripcion, imagenNueva } = registro

		if (nombre !== '' && descripcion !== '') {
			if (imagenNueva !== undefined) {
				//Cuando Hay una imagen nueva
				let formatosPermitidos = ['.png', '.jpeg', '.jpg', '.JPEG']
				console.log('imagenNueva', imagenNueva)
				let formato = typeof (imagenNueva) !== "string" ? imagenNueva.type.replace('image/', '.') : null;
				//Pregunta si el formato y peso son correctos
				if (imagenNueva !== '' && imagenNueva.size < 2000000 && formatosPermitidos.includes(formato)) {
					ProcedimientoPut()
				} else { errores('ImagenIncorrecta', setError) }
			} else ProcedimientoPut()

		} else errores('CampoVacio', setError)

	}


	return (
		<div>
			<div className="row">
				<div className="col-md-12">
					{error.activo ? <span className="error"> Error: {error.mensaje}</span> : null}
					<div><hr />

						<div className='row'>
							<div className='col-md-9'>
								<div className='custom-control custom-switch '>
									<input type='checkbox' name="activo" className='custom-control-input form-control-lg mb-4' id='customSwitches'
										checked={registro.activo}
										onChange={e => setRegistro({ ...registro, activo: !registro.activo })}
										readOnly
									/>
									<label className='custom-control-label' htmlFor='customSwitches'>Activar/Desactivar</label>
								</div>
								<MDBInput
									label="Nombre del Curso" name="nombre" required className={error.nombre ? "form-control is-invalid" : null} outline
									value={registro.nombre}
									onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
								/>

								<div className="input-group">
									<div className="input-group-prepend">
									</div>
									<div className="custom-file">
										<input type="file" className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01"
											name="imagen"
											onChange={recibeImagen(registro, setRegistro, funcion)}
										/>
										<label className="custom-file-label">
											Seleccionar Foto (400px x 400px)
										</label>
									</div>
								</div>

								<div className="row">
									<div className='col-md-12'>
										<MDBInput label="Descripción" name="descripcion" required className={error.campoClave ? "form-control is-invalid" : null} outline
											value={registro.descripcion}
											onChange={e => setRegistro({ ...registro, descripcion: e.target.value })}
										/>
									</div>
								</div>

							</div>
							<div className="col-md-3">
								<div className="FotoPrev">
									{funcion === 'editar' ?
										<img alt="imagen" src={
											registro.imagePrev === registro.imagen ?
												baseUrl + "/" + registro.imagePrev
												: registro.imagePrev
										} />
										:
										<img src={registro.imagePrev} />
									}
								</div>
							</div>
						</div>

						<br />
						<div className="col text-center">
							<button onClick={funcion === 'editar' ? peticionPut : peticionPost} className="btn boton_estandar">{TituloBtn} </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubirEditCurso