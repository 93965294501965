import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt, faEye, faFilePdf } from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used

const BotonesFormulario = ({ item, SeleccionarCategoria, Roll, acceso, detalle, descargar, urlDescarga, edit}) => {

    const handleDownload = (url) => {
		window.open(url, "_blank");
	  };


    return (
        <div className="col text-center">
            {!descargar  ?
                <div>

                    {detalle === true ?
                        <button
                            onClick={SeleccionarCategoria(item, 'detalle')}
                            className="btn boton_estandar_blanco"
                        ><FontAwesomeIcon icon={faEye} size="lg" />
                        </button>
                        : edit !== false ?
                            <button
                                onClick={SeleccionarCategoria(item, 'editar')}
                                className="btn boton_estandar_blanco"
                            ><FontAwesomeIcon icon={faEdit} size="lg" />
                            </button>
                        :null
                    }


                    {Roll === 'superadmin' || Roll === 'Admin' || acceso === 'full' ?
                        <button
                            onClick={SeleccionarCategoria(item, 'eliminar')}
                            className="btn boton_estandar_blanco"
                        ><FontAwesomeIcon icon={faTrashAlt} size="lg" />
                        </button>
                        : null}
                </div>
                :
                <button
                            onClick={() => handleDownload(urlDescarga)}
                            className="btn boton_estandar_blanco"
                        ><FontAwesomeIcon icon={faFilePdf} size="lg" />
                        </button>
            }



        </div>
    )
}



export default BotonesFormulario