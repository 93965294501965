import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faShoppingCart, faCashRegister, faIceCream, faCogs, faStore, faSignOutAlt, faCreditCard, faUsers, faChartBar, faClipboardList, faTachometerAlt, faKey, faMotorcycle} from '@fortawesome/free-solid-svg-icons'


const Paginacion = ({data, setDataFiltrado, PAGINACION, numPaginas, paginaAct, setPaginaAct}) => {


	const [indexPag, setIndexPag] = useState();

	// const PrimeraPag = () =>{
	// 	setDataFiltrado([...data].splice(0,PAGINACION));
	// 	setPaginaAct(1)
	// 	setIndexPag(0)
	// }

	// const UltimaPag = () =>{
	// 	let Ultima = Math.ceil(data.length / PAGINACION) 
	// 	let Index = Ultima + PAGINACION
	// 	console.log('Index',Index)
	// 	setDataFiltrado([...data].splice(Index + 1,PAGINACION));
	// 	setPaginaAct(Ultima)
	// 	setIndexPag(Index)
	// }

	const SiguientePag = () => {

		const totalElementos = data.length
		const PrimerIndex = paginaAct * PAGINACION
		if (PrimerIndex >= totalElementos){
			return;
		}else{
			setDataFiltrado([...data].splice(PrimerIndex,PAGINACION));
			setPaginaAct(paginaAct + 1)
			setIndexPag(PrimerIndex)
		}
	}

	const PrevPag = () => {

		const PrevPag = paginaAct - 1
		const Index =  indexPag - PAGINACION

		if(PrevPag <= 0 ) return;

		setDataFiltrado([...data].splice(Index,PAGINACION));
		setPaginaAct(PrevPag)
		setIndexPag(Index)
	}


	return(
            <div className='row Paginacion'>
                <div className='col-md-3'>
                    <button className='BtnPagina' onClick={PrevPag}>
                    <FontAwesomeIcon className='Rojovivo' icon={faChevronLeft} size="1x"/>
                    </button>
                </div>
                <div className='col-md-6' style={{textAlign: 'center'}}>
                    <p className='textPagina'><b>Página: </b>{paginaAct} de {numPaginas}</p>
                </div>
                <div className='col-md-3' style={{textAlign: 'right'}}>
                <button className='BtnPagina' onClick={SiguientePag} >
                    <FontAwesomeIcon className='Rojovivo' icon={faChevronRight} size="1x"/>
                </button>
                </div>
				
            </div>
	)
}



export default Paginacion