import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes, faCalendarDays, faMagnifyingGlass, faChevronLeft, faXmark } from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used
import { MDBInput } from 'mdbreact';


const Header = ({ titulo, funcion, setFuncion, Roll, Pagina, Agregar, cursoSelectNomb, RecibeBusqueda, setBusqueda, busqueda }) => {

    const [buscar, setBuscar] = useState(false)

    return (

        <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-6">
                <br />

                {titulo === 'Ver Temas' ?
                    <h3>{cursoSelectNomb}:</h3>
                    : <h2>{titulo}:</h2>}
            </div>
            {titulo === 'Escritorio' ?

                <div className="col-md-6 col-sm-6 col-xs-6">
                    <a className='FechasFiltro derecha'>
                        <FontAwesomeIcon icon={faCalendarDays} size="1x" style={{ marginRight: 10, color: '#fff' }} />
                        <span style={{ color: '#fff' }}>15/11/2022</span>
                    </a>
                </div>

                :

                <div className="col-md-6 col-sm-6 col-xs-6">
                    <div className="filtroPedidos">
                        {funcion === 'ver' ?
                            <div>
                                {Agregar === undefined ?
                                    <div>

                                        {!buscar ?
                                            <div>


                                                {titulo === 'Ver Temas' ?
                                                    <button
                                                        onClick={e => setFuncion('cursos')}
                                                        className="btn boton_estandar btn-lg derecha">
                                                        <FontAwesomeIcon icon={faChevronLeft} /> Cursos

                                                    </button>
                                                    : null}
                                                <button
                                                    onClick={e => setBuscar(true)}
                                                    className="btn boton_estandar btn-lg derecha">
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                </button>
                                                <button
                                                    onClick={e => setFuncion('crear')}
                                                    className="btn boton_estandar btn-lg derecha">
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </button>
                                            </div>
                                            :
                                            <div>
                                                <button
                                                    onClick={e => setBuscar(false)}
                                                    className="btn boton_estandar btn-lg derecha">
                                                    <FontAwesomeIcon icon={faXmark} />
                                                </button>
                                                <div style={{ float: 'right' }}>
                                                    <div className='Buscador'>
                                                        {/* <div style={{float: 'right'}}><FontAwesomeIcon icon={faChevronLeft} style={{float: 'left'}}/></div> */}
                                                        <span><FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginTop: 7, color: '#ccc' }} /></span>
                                                        <input className="inputBuscador" type="text" name="descripcion" required style={{ float: 'right', paddingLeft: 10, paddingTop: 3 }}
                                                            value={busqueda}
                                                            onChange={RecibeBusqueda}
                                                        />
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                    : 
                                    <div>
                                
                                              
                                    </div>
                                    }
                            </div>
                            : null}
                        {funcion === 'crear' || funcion === 'editar' || funcion === 'detalle' ?
                            <button
                                onClick={e => setFuncion('ver')}
                                className="btn boton_estandar btn-lg derecha">
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            : null}

                    </div>
                </div>
            }
        </div>
    )
}



export default Header