import React, { useState, useEffect } from 'react'
import VerUsuarios from './VerUsuarios.js'
import SubirUsuario from './SubirUsuario.js'
// import EditarUsuario from './EditarUsuario.js'
import axios from 'axios'
import Eliminar from '../componentes/Eliminar'
import Header from '../componentes/Header';
import Paginacion from '../componentes/Paginacion.js'

const UsuariosAdmin = ({ baseUrl, Titulo, cursos }) => {

	//almacena toda la data de categorias 
	const [tituloPag, setTituloPag] = useState(Titulo)
	const [error, setError] = useState({ activo: false })
	const [data, setData] = useState([])
	const [usuarioSelect, setUsuarioSelect] = useState({})
	const [funcion, setFuncion] = useState('cargando')
	const [alerta, setAlerta] = useState(false)
	const [copiaArray, setCopiaArray] = useState()
	const [busqueda, setBusqueda] = useState('')

	const PAGINACION = 15
	const [numPaginas, setNumPaginas] = useState()
	const [numRegistros, setNumRegistros] = useState()
	const [paginaAct, setPaginaAct] = useState(1);
	const [dataFiltrado, setDataFiltrado] = useState([])

	const errores = codigo => {
		switch (codigo) {
			case 'UserExiste':
				setError({ activo: true, mensaje: 'el usuario ya existe', campoUsuario: true })
				break
			case 'CampoVacio':
				setError({ activo: true, mensaje: 'Hay campos vacios' })
				break
			case 'Limpio':
				setError({
					activo: false, mensaje: '', campoUsuario: false,
					campoClave: false,
					campoNombreUser: false,
					campoRoll: false,
					campoSede: false
				})
				break

		}
	}

	const peticionTraeDatos = async () => {
		await axios.get(baseUrl, { params: { usuario: '1', clave: '1', unico: 'false' } })
			.then(response => {
				setData(response.data)
				setCopiaArray(response.data)
				setDataFiltrado([...response.data].splice(0, PAGINACION))
				setNumPaginas(Math.ceil(response.data.length / PAGINACION))
				setNumRegistros(response.data.length)
				setFuncion('ver')
			}).catch(error => {
				console.log(error)
			})
	}

	//realiza petición que borra de la base de datos por medio de la ID
	const peticionDelete = async () => {
		let f = new FormData();
		f.append("METHOD", "DELETE");
		await axios.post(baseUrl, f, { params: { id: usuarioSelect.id } })
			.then(response => {
				setData(data.filter(categoria => categoria.id !== usuarioSelect.id))
				setDataFiltrado(data.filter(categoria => categoria.id !== usuarioSelect.id))
				setFuncion('ver')
			}).catch(error => {
				console.log(error)
			})
	}

	//Selecciona categoria para editarla o borrarla 
	const Seleccionar = (objeto, funcion) => e => {
		setFuncion(funcion)
		setUsuarioSelect(objeto)
		if (funcion === 'editar') { setFuncion('editar') }
	}

	//Trae todas las categorias de la api
	useEffect(() => { peticionTraeDatos() }, [])

	useEffect(() => {
		switch (funcion) {
			case 'ver': return setTituloPag('Ver Usuarios')
			case 'crear': return setTituloPag('Crear Usuarios')
			case 'editar': return setTituloPag('Editar Usuarios')
			case 'eliminar': return setTituloPag('Eliminar Usuarios')
			default: return setTituloPag('Usuarios')
		}
	}, [funcion])

	const RecibeBusqueda = e => {
		setBusqueda(e.target.value)
		filtrar(e.target.value)
	}

	const filtrar = (termino) => {
		let resultado = copiaArray.filter((item) => {
			if(item.nombre.toLowerCase().includes(termino.toLowerCase()) || item.usuario.toLowerCase().includes(termino.toLowerCase())){return item} 
		})
		setDataFiltrado(resultado)
	}


	return (
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Pagina={'Usuarios'} RecibeBusqueda={RecibeBusqueda} busqueda={busqueda}/>
			<Paginacion
						data={data}
						setDataFiltrado={setDataFiltrado}
						PAGINACION={PAGINACION}
						numPaginas={numPaginas}
						paginaAct={paginaAct}
						setPaginaAct={setPaginaAct}
					/>
			<div className="row">
				<div className="col-md-12">

					{funcion === 'crear' ?
						<SubirUsuario
							data={dataFiltrado}
							setData={setDataFiltrado}
							baseUrl={baseUrl}
							setFuncion={setFuncion}
							Cursos={cursos}
							funcion={funcion}
							registroSelect={usuarioSelect}
						/>
						: null}
					{funcion === 'editar' ?
						<SubirUsuario
							data={dataFiltrado}
							setData={setDataFiltrado}
							baseUrl={baseUrl}
							setFuncion={setFuncion}
							Cursos={cursos}
							funcion={funcion}
							registroSelect={usuarioSelect}
						/>
						: null}
					{funcion === 'eliminar' ?
						<Eliminar
							nombre={'Usuario'}
							select={usuarioSelect}
							setFuncion={setFuncion}
							peticionDelete={peticionDelete}
						/>
						: null}
					{funcion === 'cargando' ?
						<div className="loader">Loading...</div>
						: null}
					{funcion === 'ver' ?
						<VerUsuarios
							data={dataFiltrado}
							seleccionaRegistro={Seleccionar}
						/>
						: null}
				</div>
			</div>
		</div>
	)
}

export default UsuariosAdmin