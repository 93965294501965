import React, {useRef}from 'react'
import { MDBTable, MDBTableBody} from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
//import TraeNombre from '../hooks/useTraeNombre'
import {TraeNombre} from '../hooks/Funciones'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay, faBook, faList, faPenToSquare, faChevronLeft, faXmark} from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import { setDefaultLocale } from 'react-datepicker';


const VerTemas = ({data, setData, seleccionaRegistro, baseUrl}) => {

	const DevulveIcono = (tipo) => {
		let icono = faCirclePlay
		if(tipo === 'video'){icono= faCirclePlay }
		else if(tipo === 'unidad'){icono= faBook }
		else if(tipo === 'texto'){icono= faList }
		else if(tipo === 'quiz'){icono= faPenToSquare }
		else if(tipo === 'trabajo'){icono= faPenToSquare }
		return <FontAwesomeIcon icon={icono} style={{ marginRight: 10, color: '#555', fontSize: '20px' }} />
	}

	



	return(
				<MDBTable hover>
				  <HeaderTabla array={['Id','Orden','Tipo','Img','Nombre','Unidad','']} />
			      <MDBTableBody>

			      	{data.map((item, index) => {
			      		return(
					        <tr key={index} 
								className="tabla_productos"	
							>
									<td>{item.id}</td>
									<td>{item.orden}</td>
									<td>{DevulveIcono(item.tipo)}</td>
									<td><div className="ImgProducto">
										{item.imagen !== '' ? 
										<img src={`${baseUrl}${item.imagen}`}/> :
										<img src={fotoVacia}/>
										}
										</div>
									</td>
									<td>{item.nombre}</td>
									<td>{TraeNombre(data, item.padre)}</td>
									<td className="tabla_funciones">
										<BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'}/>
									</td>
					        </tr>
				        )
			        })}

			      </MDBTableBody>
			    </MDBTable>
	)
}

export default VerTemas