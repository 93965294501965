import React, {useState, useEffect} from 'react'
import VerCursos from './VerCursos'
import SubirEditCurso from './SubirEditCurso'
import Eliminar  from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import {Titulos, peticionDelete} from '../hooks/Funciones'

const CursosAdmin = ({urlCursos, titulo}) => {

	const [tituloPag, setTituloPag] = useState(titulo)
	const [registroSelect, setRegistroSelect] = useState({})
	const [funcion, setFuncion] = useState('ver')
	const [cursos, setCursos] = useTraeDatos(urlCursos)
	const TitulosPagina = ['Ver Cursos', 'Crear Curso', 'Editar Curso', 'Eliminar Curso', 'Cursos']
	const [copiaArray, setCopiaArray] = useTraeDatos(urlCursos)
	const [busqueda, setBusqueda] = useState('')

	const seleccionaRegistro = (dato, funcion) => e =>{
		setRegistroSelect({...dato, imagePrev: dato.imagen})
		setFuncion(funcion === 'eliminar' ? 'eliminar' : 'editar')
	}

	const peticionDeletee = () =>{peticionDelete(registroSelect, urlCursos, cursos, setCursos, setFuncion)}
	useEffect(() => {Titulos (funcion, setTituloPag, TitulosPagina)},[funcion])

	const RecibeBusqueda = e => {
		setBusqueda(e.target.value)
		filtrar(e.target.value)
	}

	const filtrar = (termino) => {
		let resultado = copiaArray.filter((item) => {
			if(item.nombre.toLowerCase().includes(termino.toLowerCase())){
				return item
			} 
		})
		setCursos(resultado)
	}


	return(
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Pagina={'Cursos'} RecibeBusqueda={RecibeBusqueda} busqueda={busqueda}/>
            {funcion === 'crear' ?
                <SubirEditCurso
					baseUrl={urlCursos} 
					setFuncion={setFuncion}
					data={cursos} 
					setData={setCursos}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag} 
					funcion={funcion}/>
			:null}
			{funcion === 'editar' ?
				<SubirEditCurso
					baseUrl={urlCursos} 
					setFuncion={setFuncion}
					data={cursos} 
					setData={setCursos}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag} 
					registroSelect={registroSelect}
					funcion={funcion}/>
			:null}
			{funcion === 'ver' ?
				<VerCursos
					data={cursos} 
					seleccionaRegistro={seleccionaRegistro}
					setFuncion={setFuncion}
					setRegistroSelect={setRegistroSelect}
					baseUrl={urlCursos}
				/>
			:null}
			{funcion === 'eliminar' ?
				<Eliminar 
					nombre={'Operador'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee}/>
				
			:null}
		</div>
	)
}

export default CursosAdmin