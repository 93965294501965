import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Login from './paginas/Login.js';
import Admin from './paginas/Admin.js'
import Error404 from './paginas/Error404.js';     

const App = (_config) => {

	return(
      <BrowserRouter>
          <Routes>
            <Route exact path="/admin" element={<Admin />}></Route>
            <Route path="/" element={<Login />} />
          </Routes>
      </BrowserRouter>

)}


export default App