import React, { useState, useEffect } from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { useNavigate } from 'react-router-dom';

//Componentes
import Cookies from 'universal-cookie'
import axios from 'axios'


//JUANITA
import Logo from '../paginas/img/logo_juanita.png'
import EstilosAdmin from '../paginas/estilos/estilos_admin_juanita.css'

import Avatar from '../paginas/img/avatar.jpg'


import BotonAdmin from '../componentesAdmin/componentes/BotonAdmin.js'
import HeaderUserPrin from '../componentesAdmin/componentes/HeaderUserPrin.js'
import useTraeDatos from '../componentesAdmin/hooks/useTraeDatos';

// //Modulos
import UsuariosAdmin from '../componentesAdmin/usuarios/UsuariosAdmin'
import CursosAdmin from '../componentesAdmin/cursos/CursosAdmin'
import TemasAdmin from '../componentesAdmin/temas/TemasAdmin'
import ConfiguracionAdmin from '../componentesAdmin/configuracion/ConfiguracionAdmin.js'
import ArchivosAdmin from '../componentesAdmin/archivos/ArchivosAdmin.js'

console.log('ENVSS', process.env.REACT_APP_NAME_APP)

const Admin = ({ _config }) => {

	const APLICACION = process.env.REACT_APP_NAME_APP
	//almacena toda la data de mediodePagos 
	const cookies = new Cookies()
	//console.log(cookies.get('nombre'))

	//const {baseUrl, titulo, sede, nombreImpuesto, urlProducto} = _config._config
	const baseUrl = process.env.REACT_APP_BASE_URL
	const api = process.env.REACT_APP_API
	const NombreUsuario = cookies.get('nombre')
	const idUsuario = cookies.get('id')
	const Usuario = cookies.get('usuario')
	const Roll = cookies.get('roll')
	//const history = useHistory();
	const navigate = useNavigate();

	//Urls

	const urlUsuarios = baseUrl + api + 'usuarios/'
	const urlCursos = baseUrl + api + 'cursos/'
	const urlTemas = baseUrl + api + 'temas/'
	const urlConfiguraciones = baseUrl + api + 'configuraciones/'
	const urlArchivos = baseUrl + api + 'archivos/'

	//ESTADOS
	const [cursos] = useTraeDatos(urlCursos)
	const [menuActive, setMenuActive] = useState(true)

	//---------------------PAGINA DE INICIO -----------------------
	const [seleccionado, setSeleccionado] = useState('Cursos')
	//-------------------------------------------------------------

	const seleccionar = pagina => e => setSeleccionado(pagina)

	const cerrarSesion = (nombre) => e => {
		cookies.remove('id', { path: "/" })
		cookies.remove('nombre', { path: "/" })
		cookies.remove('usuario', { path: "/" })
		cookies.remove('roll', { path: "/" })
		navigate('/');
	}

	useEffect(() => {
		document.title = "Juanita Rubio Admin App"
	},[])	


	return (
		<div className="row principal">
			<div className="col-xs-12 col-sm-12 col-md-3">
				<div className="barra_izquierda">

					<HeaderUserPrin Logo={Logo} Avatar={Avatar} nombre={NombreUsuario} idUsuario={idUsuario} Roll={Roll} usuario={Usuario} menuActive={menuActive} setMenuActive={setMenuActive} />

						<div className={menuActive ? 'activo' : 'hidden'}>
							{/* <BotonAdmin nombre={'Escritorio'} onClick={seleccionar} active={seleccionado} roll={Roll} verPara={'Vendedor'} /> */}
							<BotonAdmin nombre={'Cursos'} onClick={seleccionar} active={seleccionado} roll={Roll} verPara={'Vendedor'} />
							<BotonAdmin nombre={'Temas'} onClick={seleccionar} active={seleccionado} roll={Roll} verPara={'Vendedor'} />
							<BotonAdmin nombre={'Usuarios'} onClick={seleccionar} active={seleccionado} roll={Roll} />
							<BotonAdmin nombre={'Imagenes'} onClick={seleccionar} active={seleccionado} roll={Roll} />
							<BotonAdmin nombre={'Cerrar Sesión'} onClick={cerrarSesion} active={seleccionado} roll={Roll} verPara={'Vendedor'} />
						</div>

				</div>
			</div>
			<div className="col-xs-12 col-sm-12 col-md-9">
				<div className="barra_derecha">
					<div className="contenedorAdmin">
						<div className="contenedor">

							{seleccionado === 'Cursos' ?
								<CursosAdmin
									urlCursos={urlCursos}
									titulo={'Cursos'}
								/>
								: null}

							{seleccionado === 'Temas' ?
								<TemasAdmin
									urlTemas={urlTemas}
									titulo={'Temas'}
									cursos={cursos}
								/>
								: null}


							{seleccionado === 'Usuarios' ?
								<UsuariosAdmin
									baseUrl={urlUsuarios}
									Titulo={'Usuarios'}
									cursos={cursos} />
								: null}


							{seleccionado === 'Imagenes' ?
								<ArchivosAdmin
									urlArchivos={urlArchivos}
									titulo={'Imagenes'}
								/>
								: null}

							{/* {seleccionado === 'Configuración' ?
								<ConfiguracionAdmin
									urlConfiguraciones={urlConfiguraciones}
									titulo={'Configuracion'}
								/>
								: null} */}



						</div>
					</div>
				</div>
			</div>
		</div>

	)
}



export default Admin