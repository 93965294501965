import React from 'react'
import { MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
//import TraeNombre from '../hooks/useTraeNombre'
import {TraeNombre} from '../hooks/Funciones'

const VerArchivos = ({data, seleccionaRegistro, baseUrl}) => {

	return(
				<MDBTable hover>
					<MDBTableHead>
						<tr>
							<th>Id</th>
							<th>Img</th>
							<th><div style={{ maxWidth: 350, wordWrap: 'break-word', wordBreak: 'break-all'}}>Url</div></th>
							<th></th>
						</tr>
					</MDBTableHead>
			      <MDBTableBody>

			      	{data.map((item, index) => {
			      		return(
					        <tr key={index} className="tabla_productos">
							  <td>{item.id}</td>
							  <td><div className="ImgProducto"><img src={`${baseUrl}${item.path}`}/></div></td>
							  <td>
							  	<div style={{ maxWidth: 350, wordWrap: 'break-word', wordBreak: 'break-all' }}>
									{`${baseUrl}${item.path}`}
								</div>
							  </td>
                              <td className="tabla_funciones">
							  	<BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'} edit={false}/>
					          </td>
					        </tr>
				        )
			        })}

			      </MDBTableBody>
			    </MDBTable>
	)
}

export default VerArchivos