import React, {useState, useEffect} from 'react'
import VerTemas from './VerTemas'
import SubirEditCurso from './SubirEditCurso'
import ListaCursos from './ListaCursos'
import Eliminar  from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import {Titulos, peticionDelete} from '../hooks/Funciones'
import axios from 'axios'

const TemasAdmin = ({urlTemas, titulo, cursos, urlProductos}) => {

	const [tituloPag, setTituloPag] = useState(titulo)
	const [registroSelect, setRegistroSelect] = useState({})
	const [funcion, setFuncion] = useState('cursos')
	const [temas, setTemas] = useState([])
	const [copiaTemas, setCopiaTemas] = useState([])
	const [busqueda, setBusqueda] = useState('')
	const [cursoSelect, setCursoSelect] = useState('temas')
	const [cursoSelectNomb, setCursoSelectNomb] = useState('')
	const TitulosPagina = ['Ver Temas', 'Crear Tema', 'Editar Tema', 'Eliminar Tema', 'Temas']
	//const [productos] = useTraeDatos(urlProductos)
	const [productos, setProductos] = useState([])
	const [tamOrden, setTamOrden] = useState(0)
	

	const seleccionaRegistro = (dato, funcion) => e =>{
		setRegistroSelect({...dato, imagePrev: dato.imagen})
		console.log({...dato, imagePrev: dato.imagen})
		setFuncion(funcion === 'eliminar' ? 'eliminar' : 'editar')
	}

	const peticionDeletee = () =>{peticionDelete(registroSelect, urlTemas, temas, setTemas, setFuncion)}
	useEffect(() => {Titulos (funcion, setTituloPag, TitulosPagina)},[funcion])

	const peticionTraeDatos = async(id)=>{
		await axios.get(urlTemas, {params: {id_curso: id}})
		.then (response => {
			//setTemas(response.data)
			setTemas(response.data.sort((a, b) => a.orden - b.orden))
			console.log('temas',response.data)
			setCopiaTemas(response.data) 
			setFuncion('ver')
		}).catch(error =>{
			console.log(error)
		})
	}

	const RecibeBusqueda = e => {
		setBusqueda(e.target.value)
		console.log(e.target.value)
		filtrar(e.target.value)
	}

	const filtrar = (termino) => {
		console.log('termino',termino)
		console.log('copiaTemas',copiaTemas)
		let resultado = copiaTemas.filter((item) => {
			if(item.nombre.toLowerCase().includes(termino.toLowerCase())){
				return item
			} 
		})
		setTemas(resultado)
	}

	useEffect(()=>{
		setTamOrden(temas.length)
	},[temas])


	return(
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Pagina={'Cursos'} cursoSelectNomb={cursoSelectNomb} RecibeBusqueda={RecibeBusqueda} setBusqueda={setBusqueda} busqueda={busqueda}/>
            {funcion === 'cursos' ?
                <ListaCursos
					cursos={cursos}
					setCursoSelect={setCursoSelect}
					setFuncion={setFuncion}
					setCursoSelectNomb={setCursoSelectNomb}
					peticionTraeDatos={peticionTraeDatos}
					/>
			:null}
			{funcion === 'crear' ?
                <SubirEditCurso
					baseUrl={urlTemas} 
					setFuncion={setFuncion}
					data={temas} 
					setData={setTemas}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag} 
					funcion={funcion}
					cursoSelect={cursoSelect}
					tamOrden={tamOrden}/>
			:null}
			{funcion === 'editar' ?
				<SubirEditCurso
					baseUrl={urlTemas} 
					setFuncion={setFuncion}
					data={temas} 
					setData={setTemas}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag} 
					registroSelect={registroSelect}
					funcion={funcion}
					cursoSelect={cursoSelect}
					tamOrden={tamOrden}/>
			:null}
			{funcion === 'ver' ?
				<VerTemas
					data={temas}
					setData={setTemas}
					seleccionaRegistro={seleccionaRegistro}
					setFuncion={setFuncion}
					setRegistroSelect={setRegistroSelect}
					baseUrl={urlTemas}
					idCurso={cursoSelect}
				/>
			:null}
			{funcion === 'eliminar' ?
				<Eliminar 
					nombre={'Operador'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee}/>
				
			:null}
		</div>
	)
}

export default TemasAdmin