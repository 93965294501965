import React from 'react'
import { MDBTable, MDBTableBody} from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
//import TraeNombre from '../hooks/useTraeNombre'
import {TraeNombre} from '../hooks/Funciones'

const VerCursos = ({data, seleccionaRegistro, baseUrl}) => {

	return(
				<MDBTable hover>
				  <HeaderTabla array={['Img','Id','Activo','Nombre','Descripcion','']} />
			      <MDBTableBody>

			      	{data.map((item, index) => {
			      		return(
					        <tr key={index} className="tabla_productos">
							  <td><div className="ImgProducto"><img src={`${baseUrl}${item.imagen}`}/></div></td>
					          <td>{item.id}</td>
							  <td>{item.activo === 'true' ? 'Si' : 'No'}</td>
							  <td>{item.nombre}</td>
							  <td>{item.descripcion}</td>
                              <td className="tabla_funciones">
							  	<BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'}/>
					          </td>
					        </tr>
				        )
			        })}

			      </MDBTableBody>
			    </MDBTable>
	)
}

export default VerCursos