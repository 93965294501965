import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {faGraduationCap, faImage, faTachometerAlt, faBook, faDolly, faClipboardCheck, faArrowRight, faTruck, faClipboardList, faMapLocationDot, faUserGraduate, faPencil, faLocationDot, faChartBar, faCogs, faSignOut, faUserFriends, faCalendarDays, faTicket, faTaxi, faCoins, faUsers} from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUserFriends, faArrowRight, faShoppingCart, faCashRegister, faIceCream, faCogs, faStore, faSignOutAlt, faCreditCard, faUsers, faChartBar, faClipboardList, faTachometerAlt, faKey, faMotorcycle} from '@fortawesome/free-solid-svg-icons'

const BotonAdmin = ({nombre, onClick, sub, active, roll, verPara}) => {

	const EncuentraIcono = icon => {
		
		switch(icon){
			case 'Escritorio': return faTachometerAlt
			case 'Cursos': return faGraduationCap
			case 'Temas': return faBook
			case 'Usuarios': return faUserFriends
			case 'Configuración': return faCogs
			case 'Actividades': return faClipboardCheck
			case 'Certificados': return faUserGraduate
			case 'Cerrar Sesión': return faSignOut
			case 'Productos': return faPencil
			case 'Ubicaciones': return faLocationDot 
			case 'Zonas de Envio': return faMapLocationDot 
			case 'Cargas de Envio': return faTruck
			case 'Reglas de Envio': return faDolly
			case 'Imagenes': return faImage
			default: return faArrowRight
		}
	}


	return(

			<button  
				// className={sub ? "sub-item" : "item"}
				style={roll === verPara || roll === 'Admin' ? null : null}
				className={active === nombre ? sub ? "sub-item active" : "item active" : sub ? "sub-item" : "item"}
				onClick={onClick(nombre)}>	
					<FontAwesomeIcon icon={EncuentraIcono(nombre)} size="1x"/>
					{' '+nombre}
			</button>
	)
}



export default BotonAdmin