import React, {useState, useEffect} from 'react'

const ListaCursos = ({cursos, setCursoSelect, setFuncion, setCursoSelectNomb, peticionTraeDatos}) => {

  const Seleccionar = (id, nombre) => {
    setCursoSelect(id)
    setCursoSelectNomb(nombre)
    peticionTraeDatos(id)
  } 

  return (
    <div className='row'>
      { cursos.map((item, index) => {
          return(
              <div className='col-md-4' style={{padding: 0}} key={index}>
                <button className='btn CardCurso' onClick={e => Seleccionar(item.id, item.nombre)}>
                <span>{item.nombre}</span>
                </button>
              </div>
          )
      })
      }
    </div>
  )
}

export default ListaCursos