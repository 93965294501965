import React from 'react'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import Avatar from '../../paginas/img/avatar.jpg'
import HeaderTabla from '../componentes/HeaderTabla'
import BotonesFormulario from '../componentes/BotonesFormulario'
import moment from 'moment'

const VerUsuarios = ({data, seleccionaRegistro}) => {


	return(
		<div>
			<MDBTable hover>
			  <HeaderTabla array={['Img','Estado','Inscrip','Nombre','Usuario','']} />
		      <MDBTableBody>

		      	{data.map((item, index) => {
		      		return(
				        <tr key={index}>
				          <td><div className="img_user"><img src={Avatar}/></div></td>
				          <td><div className={`estados ${item.activo}`} >{item.activo}</div></td>
						  <td>{moment(item.fecha_ins).format('DD-MM-YYYY')}</td>	
						  <td>{item.nombre}</td>	
						  <td>{item.usuario}</td>		
				          <td className="tabla_funciones">
						  	<BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'}/>
				          </td>
				        </tr>
			        )

		        })}

		      </MDBTableBody>
		    </MDBTable>
		</div>
	)
}

export default VerUsuarios